import { createApp } from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"

import "./assets/css/main.css"
import "./assets/css/form.css"
import "./assets/css/fonts.css"
import "./assets/css/variables.css"
import "./assets/css/text.css"
import "./assets/css/www.css"
import "./assets/css/animate.css"

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

const app = createApp(App)

app
    .use(store)
    .use(router)
    .use(Toast, {
        transition: "Vue-Toastification__bounce",
        maxToasts: 20,
        newestOnTop: true,
    })
    .use(FloatingVue, {
        disposeTimeout: 5000,themes: { tooltip: { placement: 'top', triggers: ['hover', 'focus', 'touch', 'click'], } } })
    .mount('#app')
